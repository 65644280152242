<template>
  <vx-card>
    <div>
      <h3>لیست کاربران همکار در فروش</h3>
    </div>
    <div class="mt-5">
      <vs-table
      >
          <vs-th> شناسه </vs-th>
          <vs-th> نام کاربر </vs-th>
          <vs-th> سطح </vs-th>
          <vs-th> تاریخ ارتقا سطح </vs-th>
          <vs-th> تاریخ فعالسازی </vs-th>
          <vs-th> مشاهده </vs-th>
          <vs-tr  :key="data.id" v-for="data in dataSalesCooperation">
            <vs-td>{{ data.id }}</vs-td>
            <vs-td v-if="data.user">{{ data.user.name }}</vs-td>
            <vs-td>{{ data.level }}</vs-td>
            <vs-td>{{ data.level_up_at }}</vs-td>
            <vs-td>{{ data.created_at }}</vs-td>
            <vs-td>
              <vs-button color="warning" radius icon="book" />
            </vs-td>
          </vs-tr>
      </vs-table>
    </div>
  </vx-card>
</template>

<script>
export default {
  name: "UsersSalecooperation",
  data() {
    return {
      dataSalesCooperation: [],
    };
  },
  methods: {
    getUsersSales() {
      this.$vs.loading();
      this.$http
        .get("admin/usersinsolescooperation")
        .then((res) => {
          this.$vs.loading.close();
          this.dataSalesCooperation = res.data.params;
        })
        .catch((err) => {
          this.$vs.loading.close()
          console.log(err);
        });
    },
  },
  created() {
    this.getUsersSales();
  },
};
</script>

<style scoped>
</style>
